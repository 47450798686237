import { render, staticRenderFns } from "./AdminProducts.vue?vue&type=template&id=65b3e42c&scoped=true&"
import script from "./AdminProducts.vue?vue&type=script&lang=js&"
export * from "./AdminProducts.vue?vue&type=script&lang=js&"
import style0 from "./AdminProducts.vue?vue&type=style&index=0&id=65b3e42c&lang=scss&scoped=true&"
import style1 from "./AdminProducts.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65b3e42c",
  null
  
)

export default component.exports